export default {
	data() {
		return {
			arCategorias: [],
			arVideos: [],
			blLoading: true,
			obForm: {
				nome: null,
				data: null,
				id_categoria: null
			},
			obFormPesquisa: {
				nome: null,
				data: null,
				id_categoria: null
			}
		};
	},
	created() {
		this.listar();
	},
	methods: {
		listar() {
			this.$root.$api.get("video/listar").then(response => {
				this.arVideos = response.retorno.videos;
				this.arCategorias = response.retorno.categorias;

				this.blLoading = false;
			});
		},
		filtrar() {
			this.obFormPesquisa.nome = this.obForm.nome;
			this.obFormPesquisa.id_categoria = this.obForm.id_categoria;

			if (this.obForm.data) {
				this.obFormPesquisa.data = this.obForm.data
					.split("-")
					.reverse()
					.join("/");
			} else {
				this.obFormPesquisa.data = null
			}
		},
	},
	computed: {
		listaVideos() {
			if (!this.arVideos) {
				return [];
			}

			return this.arVideos.filter(obVideo => {
				return (
					(!this.obFormPesquisa.nome || obVideo.nome.toLowerCase().indexOf(this.obFormPesquisa.nome.toLowerCase()) >= 0) &&
					(!this.obFormPesquisa.data || obVideo.data == this.obFormPesquisa.data) &&
					(!this.obFormPesquisa.id_categoria || obVideo.id_categoria == this.obFormPesquisa.id_categoria)
				)
			});
		}
	}
}